// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/dm-sans/dm-sans-regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/dm-sans/dm-sans-regular.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/dm-sans/dm-sans-500.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "../fonts/dm-sans/dm-sans-500.woff";
import ___CSS_LOADER_URL_IMPORT_4___ from "../fonts/dm-sans/dm-sans-700.woff2";
import ___CSS_LOADER_URL_IMPORT_5___ from "../fonts/dm-sans/dm-sans-700.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:DM Sans;font-style:normal;font-weight:400;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:DM Sans;font-style:normal;font-weight:500;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:DM Sans\\ 700;font-style:normal;font-weight:700;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}", "",{"version":3,"sources":["webpack://./resources/sass/fonts.scss"],"names":[],"mappings":"AAEA,WAIC,iBAAA,CAHA,mBAAA,CACA,iBAAA,CACA,eAAA,CAEA,4HAAD,CAMA,WAIC,iBAAA,CAHA,mBAAA,CAEA,iBAAA,CADA,eAAA,CAGA,4HAHD,CASA,WAIC,iBAAA,CAHA,wBAAA,CAEA,iBAAA,CADA,eAAA,CAGA,4HAND","sourcesContent":["\n/* DM Sans-regular */\n@font-face {\n\tfont-family: 'DM Sans';\n\tfont-style: normal;\n\tfont-weight: 400;\n\tfont-display: swap;\n\tsrc: local(''),\n\t\t\turl('../fonts/dm-sans/dm-sans-regular.woff2') format('woff2'),\n\t\t\turl('../fonts/dm-sans/dm-sans-regular.woff') format('woff');\n\t}\n\n/* DM Sans-500 */\n@font-face {\n\tfont-family: 'DM Sans';\n\tfont-weight: 500;\n\tfont-style: normal;\n\tfont-display: swap;\n\tsrc: local(''),\n\t\t\turl('../fonts/dm-sans/dm-sans-500.woff2') format('woff2'),\n\t\t\turl('../fonts/dm-sans/dm-sans-500.woff') format('woff');\n\t}\n\n/* DM Sans-700 */\n@font-face {\n\tfont-family: 'DM Sans 700';\n\tfont-weight: 700;\n\tfont-style: normal;\n\tfont-display: swap;\n\tsrc: local(''),\n\t\t\turl('../fonts/dm-sans/dm-sans-700.woff2') format('woff2'),\n\t\t\turl('../fonts/dm-sans/dm-sans-700.woff') format('woff');\n\t}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
