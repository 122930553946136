let tracker;

document.addEventListener('DOMContentLoaded', function () {
	const intervalId = setInterval(pushDataToMatomo, 100);

	function pushDataToMatomo() {
		if (window.Matomo && window.MatomoTagManager) {
			tracker = window.Matomo.getTracker(
				window.MatomoTagManager.containers[0].variables[0].parameters.matomoUrl + "matomo.php",
				window.MatomoTagManager.containers[0].idsite
			);
			clearInterval(intervalId);
		}
	}
});

document.addEventListener('submit', (event) => {
	if (event.target.closest('#register:valid')) {
		// event.preventDefault();
		const first_name = document.querySelector('[name="first_name"]').value;
		const last_name = document.querySelector('[name="last_name"]').value;
		tracker.trackEvent('Content Interaction', 'Click', 'Benutzer registriert', first_name + ' ' + last_name);
	}
});
