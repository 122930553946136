import Autocomplete from '../js/vendor/autocomplete.esm';
import onEvent from "./vendor/autocomplete.esm";

export default class hereApiGeocoding {

	constructor() {

		this.DOM = {
			city: '#city',
			zipcode: '#zipcode',
			country: '#country',
			street: '#street',
		}

		this.is_enabled = false; // defines if the address validation is enabled

		/**
		 * fetch element DOM
		 * @type {Element}
		 */

		this.city = document.querySelector(this.DOM.city);
		this.zipcode = document.querySelector(this.DOM.zipcode);
		this.country = document.querySelector(this.DOM.country);
		this.street = document.querySelector(this.DOM.street)
	}


	autocomplete () {

		new Autocomplete("street", {

			// search delay
			delay: 200,

			// add button 'x' to clear the text from
			// the input filed
			clearButton: true,

			// default selects the first item in
			// the list of results
			selectFirst: true,

			// add text to the input field as you move through
			// the results with the up/down cursors
			insertToInput: true,

			// the number of characters entered
			// should start searching
			howManyCharacters: 2,

			// the characters entered in
			// the input field are cached
			cache: true,

				onSearch: ({ currentValue }) => {
					const api = `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=5jyZrRUMWbvBcqa9kyEYd0B6xh9-dZgszJZLKSeXuFo&query=${encodeURI( currentValue )}`;
					return new Promise((resolve) => {

						fetch(api)
							.then((response) => response.json())
							.then((data) => {
								resolve(data.suggestions
									.filter(el => {
										return !!(el.address.city && el.address.postalCode && el.address.city && el.address.street && el.address.houseNumber);
									}));
								console.log(data.suggestions)
							})
							.catch((error) => {
								console.error(error);
							});
					});
				},

				onResults: ({ matches, template }) => {

					// checking if we have results if we don't
					// take data from the noResults callback
					return matches === 0
					  ? template
					  : matches
						.sort(
						(a, b) =>
							a.label.localeCompare(b.label) || a.name.localeCompare(b.name)
						)
						.map((el) => {
							return `
								<li>
									<div style="display: flex;">
										<div class="info">
											<div>${el.address.street} ${el.address.houseNumber}</div>
											<div>${el.address.postalCode} ${el.address.city}</div>
											<div>${el.address.country}</div>
										</div>
									</div>
								</li>`;
						}).join("");
				  },

				  // the onSubmit function is executed when the user
				  // submits their result by either selecting a result
				  // from the list, or pressing enter or mouse button
				  onSubmit: ({ index, element, object, results }) => {
                      event.preventDefault();
					  this.city.value = object.address.city;
					  this.zipcode.value = object.address.postalCode;
					  this.country.value = object.address.country;
					  this.street.value = object.address.street + " " + object.address.houseNumber;
                      return false;
				  },

				  // get index and data from li element after
				  // hovering over li with the mouse or using
				  // arrow keys ↓ | ↑
				  onSelectedItem: ({ index, element, object }) => {
                      if(object === null) {
                          return;
                      }

                      this.city.value = object.address.city;
                      this.zipcode.value = object.address.postalCode;
                      this.country.value = object.address.country;
                      this.street.value = object.address.street + " " + object.address.houseNumber;
                  },

				  // the callback presents no results
				  noResults: ({ element, template }) => {
					template(`<li>Keine Orte gefunden: "${element.value}"</li>`);
				  },

				  onReset: (element) => {

				}


		});
	}

	init() {
        document.addEventListener( "keydown", this._handleKeys);

		if (this.autocomplete !== null) {
			if(this.is_enabled === true) {
				this.autocomplete();
			}
		} else {
			// console.error(`${this.DOM.headvisualButton} does not exist in the DOM!`)
		}
	}

    _handleKeys(e) {
        var matches = function(el, selector) {
            return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
        };

        // key pressed isn't up (38) or down (40)
        if(e.keyCode !== 38 && e.keyCode !== 40) {
            return;
        }

        // check if it's all about the input field street
        if(matches(e.target, '#street')) {
            let target = e.target;
            let target_attr = target.getAttribute("aria-activedescendant");
            if(target_attr == null) {
                return;
            }

            let target_attr_match = target_attr.match(/^auto\-selected\-option\-([0-9]+)$/i);
            if(target_attr_match === null || typeof target_attr_match[1] === "undefined") {
                return;
            }

            // actual current index
            let current_index = target_attr_match[1];

            // scroll to position
            let current_li = document.querySelector("#auto-street-results li[aria-posinset='" + current_index + "']");
            current_li.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
        }
    }

}
