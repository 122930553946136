import './matomo';
import './tracker';
import './bootstrap';

// Local Fonts
import '../sass/fonts.scss';

import hereApiGeocoding from './hereApiGeocoding';
const autocomplete = new hereApiGeocoding();
autocomplete.init();
